import { useEffect } from 'react';
import { LoginForm } from '../components/LoginForm';
import { useAuthStore } from '@/stores/auth.store';
import { AuthLayout } from '../../../app/layouts/AuthLayout';
import { useMachineStore } from '@/stores/machine.store';
import { useQueryClient } from '@tanstack/react-query';

export function LoginPage() {
  const { logout } = useAuthStore();
  const { resetMachines } = useMachineStore();
  const queryClient = useQueryClient();

  useEffect(() => {
    // Clear all data when visiting login page
    logout();
    resetMachines();
    queryClient.clear();
  }, []);

  return (
    <AuthLayout
      title="Đăng nhập"
      subtitle="Chào mừng bạn quay trở lại! Vui lòng đăng nhập để tiếp tục."
      type="login"
    >
      <LoginForm />
    </AuthLayout>
  );
}
