import { Card } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { formatCurrency, getCategoryIcon, getCategoryLabel, getTransactionDescription, getTransactionColor, getTransactionPrefix } from "@/lib/utils";
import { useReportStore } from '@/stores/report.store';
import { IconMapper } from "@/components/common/IconMapper";
import { DailyReport } from "@/types/report";
import { TransactionWithCategory } from "@/types/transaction";

interface Props {
  isLoading?: boolean;
  data?: DailyReport[];
}

export function DailyTransactions({ isLoading, data }: Props) {
  const dailyReports = useReportStore((state) => state.dailyReports);

  if (isLoading) {
    return (
      <div className="space-y-6">
        {[1, 2].map((index) => (
          <div key={index} className="space-y-2">
            <div className="flex items-center justify-between">
              <Skeleton className="h-5 w-24" />
              <Skeleton className="h-5 w-32" />
            </div>

            <div className="space-y-2">
              {[1, 2].map((item) => (
                <Card key={item} className="p-3">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <Skeleton className="h-8 w-8 rounded-lg" />
                      <Skeleton className="h-4 w-24" />
                    </div>
                    <Skeleton className="h-4 w-16" />
                  </div>
                </Card>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (!dailyReports?.length) {
    return null;
  }

  return (
    <div className="space-y-6">
      {dailyReports.map((report) => (
        <div key={report.date} className="space-y-2">
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium text-gray-900">
              {(() => {
                const [day, month] = report.date.split('/');
                const today = new Date();
                const reportDate = new Date(today.getFullYear(), parseInt(month) - 1, parseInt(day));
                const diffTime = Math.floor((today.getTime() - reportDate.getTime()) / (1000 * 60 * 60 * 24));
                
                if (diffTime === 0) return `Hôm nay, ${report.date}`;
                if (diffTime === 1) return `Hôm qua, ${report.date}`;
                return report.date;
              })()}
            </span>
            <span className="text-sm text-gray-500">
              Tổng: {formatCurrency(report.total)}
            </span>
          </div>

          <div className="space-y-2">
            {report.transactions.map((transaction: TransactionWithCategory) => (
              <Card key={transaction.id} className="p-3">
                <div className="flex items-start gap-3">
                  <div className="w-10 h-10 rounded-xl bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center">
                    <IconMapper 
                      iconName={getCategoryIcon(transaction.categoryType)} 
                      className="w-5 h-5 text-gray-600" 
                    />
                  </div>
                  
                  <div className="flex-1 flex items-start justify-between min-w-0">
                    <div className="min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {getCategoryLabel(transaction.categoryType)}
                        {transaction.related_transactions && transaction.related_transactions.length > 0 ? 
                          <span className="ml-1 text-xs text-gray-500">
                            ({transaction.related_transactions.length + 1} giao dịch)
                          </span> 
                          : null
                        }
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        {transaction.description || getTransactionDescription(transaction)}
                      </p>
                    </div>
                    <p className={`text-sm font-medium ${getTransactionColor(transaction.type)}`}>
                      {getTransactionPrefix(transaction.type)}{formatCurrency(Math.abs(transaction.amount))}
                    </p>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
