import { useEffect } from 'react';
import { ForgotPasswordForm } from '../components/ForgotPasswordForm';
import { AuthLayout } from '../../../app/layouts/AuthLayout';
import { useAuthStore } from '@/stores/auth.store';
import { useMachineStore } from '@/stores/machine.store';
import { useQueryClient } from '@tanstack/react-query';

export function ForgotPasswordPage() {
  const { logout } = useAuthStore();
  const { resetMachines } = useMachineStore();
  const queryClient = useQueryClient();

  useEffect(() => {
    // Clear all data when visiting forgot password page
    logout();
    resetMachines();
    queryClient.clear();
  }, []);

  return (
    <AuthLayout
      title="Quên mật khẩu?"
      subtitle={
        <>
          Nhập email của bạn và chúng tôi sẽ gửi hướng dẫn đặt lại mật khẩu.
        </>
      }
    >
      <ForgotPasswordForm />
    </AuthLayout>
  );
}
