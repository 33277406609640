import { api, ApiResponse } from './api';
import type { ReportSummary, ReportParams } from '@/types/report';

export const reportService = {
  getReport: async (params: ReportParams): Promise<ReportSummary> => {
    let parseParams = {
      ...params,
      funds: params.funds?.join(',') || undefined,
      wallets: params.wallets?.join(',') || undefined,
      tags: params.tags?.join(',') || undefined
    }

    const response = await api.get<ApiResponse<ReportSummary>>('/api/transactions/report', { params: parseParams });
    return {
      startBalance: response.data.data.startBalance,
      endBalance: response.data.data.endBalance,
      difference: response.data.data.difference,
      differencePercentage: response.data.data.differencePercentage,
      income: response.data.data.income,
      expense: response.data.data.expense
    };
  }
};
