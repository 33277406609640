import { api, ApiResponse } from './api';
import type { AllocationRequest, AllocationResponse, CreateTransactionDto, Transaction, TransactionFilterParams } from '@/types/transaction';

export const transactionService = {
  create: async (data: CreateTransactionDto): Promise<Transaction> => {
    const response = await api.post<ApiResponse<Transaction>>('/api/transactions', data);
    return response.data.data;
  },

  getById: async (id: string): Promise<Transaction> => {
    const response = await api.get<ApiResponse<Transaction>>(`/api/transactions/${id}`);
    return response.data.data;
  },

  delete: async (id: string): Promise<void> => {
    await api.delete(`/api/transactions/${id}`);
  },

  filter: async (params: TransactionFilterParams): Promise<{transactions: Transaction[], total: number}> => {
    let parseParams = {
      ...params,
      funds: params.funds?.join(',') || undefined,
      wallets: params.wallets?.join(',') || undefined,
      tags: params.tags?.join(',') || undefined
    }
    const response = await api.get<ApiResponse<{transactions: Transaction[], total: number}>>('/api/transactions', { params: parseParams });
    return response.data.data;
  },

  allocate: async (data: AllocationRequest): Promise<AllocationResponse> => {
    const response = await api.post<ApiResponse<AllocationResponse>>('/api/transactions/allocate', data);
    return response.data.data;
  }
};
