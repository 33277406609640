import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { useMachineStore } from '@/stores/machine.store'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from '@/components/ui/skeleton'
import { Machine } from '@/types/machine'
import { useQuery } from '@tanstack/react-query'
import { machineService } from '@/services/machine.service'

interface MachineListProps {
  machines: Machine[]
}

export function MachineList({ machines }: MachineListProps) {
  const navigate = useNavigate()
  const { selectedMachine, selectMachine } = useMachineStore()

  const handleMachineSelect = async (machineId: string) => {
    try {
      await selectMachine(machineId)
      navigate('/machine')
    } catch (error) {
      console.error('Failed to select machine:', error)
    }
  }

  if (!machines?.length) {
    return (
      <Card className="w-full">
        <CardContent className="py-8">
          <div className="text-center">
            <p className="text-muted-foreground">Bạn chưa có cỗ máy nào</p>
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {machines.map((machine) => (
        <Card
          key={machine.id}
          className={`w-full cursor-pointer transition-all hover:shadow-md ${
            selectedMachine?.id === machine.id ? 'border-primary' : ''
          }`}
          onClick={() => handleMachineSelect(machine.id)}
        >
          <CardHeader>
            <CardTitle className="text-lg">{machine.name}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-sm text-muted-foreground">
              <p>Số dư chưa phân bổ: {machine.un_allocated.toLocaleString()} {machine.currency}</p>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  )
}
