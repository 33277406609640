import axios, { AxiosError, AxiosResponse } from 'axios';
import { authService } from './auth.service';

export const API_URL = 'https://wealth-api.sohapage.io';

export interface ApiResponse<T> {
  success: boolean;
  data: T;
  message?: string;
  error?: {
    code: string;
    message: string;
  };
}

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Flag to track if we are refreshing token
let isRefreshing = false;
// Queue of requests waiting for token refresh
let refreshSubscribers: ((token: string) => void)[] = [];

// Function to process queued requests
const processQueue = (token: string) => {
  refreshSubscribers.forEach(callback => callback(token));
  refreshSubscribers = [];
};

// Add auth and machine headers
api.interceptors.request.use(
  async (config) => {
    // Add auth header if token exists
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Add machine header if machine exists
    const machineId = localStorage.getItem('machineId');
    if (machineId) {
      config.headers['x-machine-id'] = machineId;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle API response format and token refresh
api.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError<ApiResponse<any>>) => {
    const originalRequest = error.config;
    
    // Handle token refresh
    if (error.response?.status === 401 && originalRequest) {
      if (!isRefreshing) {
        isRefreshing = true;
        const refreshToken = localStorage.getItem('refreshToken');

        if (!refreshToken) {
          // No refresh token, clear everything and reject
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          isRefreshing = false;
          window.location.href = '/login';
          return Promise.reject(error);
        }

        try {
          const response = await authService.refreshToken(refreshToken);
          const { accessToken, refreshToken: newRefreshToken } = response;

          // Update tokens
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', newRefreshToken);
          api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

          // Process queued requests
          processQueue(accessToken);
          isRefreshing = false;

          // Retry original request with new token
          if (originalRequest.headers) {
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
          }
          return axios(originalRequest);
        } catch (refreshError) {
          // Refresh token failed, clear everything
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          isRefreshing = false;
          window.location.href = '/login';
          return Promise.reject(refreshError);
        }
      } else {
        // Wait for token refresh
        return new Promise(resolve => {
          refreshSubscribers.push(token => {
            if (originalRequest.headers) {
              originalRequest.headers['Authorization'] = `Bearer ${token}`;
            }
            resolve(axios(originalRequest));
          });
        });
      }
    }

    return Promise.reject(error);
  }
);
