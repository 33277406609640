import { Brain, Sparkles, Sprout } from "lucide-react";
import { Container } from "@/components/ui/container";
import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";

const features = [
  {
    icon: Sprout,
    iconBg: "from-emerald-100 to-emerald-200",
    iconColor: "text-emerald-600",
    title: "Phát triển nghiệp tốt",
    description: "Theo dõi và nuôi dưỡng những hành vi tài chính tích cực, xây dựng thói quen lành mạnh",
  },
  {
    icon: Sparkles,
    iconBg: "from-teal-100 to-teal-200",
    iconColor: "text-teal-600",
    title: "Kho rộng mở",
    description: "Tự do tổ chức và quản lý các khoản thu chi theo cách riêng của bạn",
  },
  {
    icon: Brain,
    iconBg: "from-cyan-100 to-cyan-200",
    iconColor: "text-cyan-600",
    title: "Trợ lý AI thông minh",
    description: "Tư vấn tài chính cá nhân hóa từ AI, giúp bạn đưa ra quyết định tốt hơn",
  },
];

export function FeaturesSection() {
  return (
    <section className="py-20 bg-white">
      <Container>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
            Tính năng nổi bật
          </h2>
          <p className="mt-4 text-gray-500 md:text-xl/relaxed">
            Công cụ thông minh giúp bạn phát triển những nghiệp tốt
          </p>
        </div>
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {features.map((feature) => {
            const Icon = feature.icon;
            return (
              <Card key={feature.title} className="border-0 shadow-lg hover:shadow-xl transition-shadow">
                <CardHeader>
                  <div className={`w-12 h-12 rounded-xl bg-gradient-to-br ${feature.iconBg} flex items-center justify-center mb-4`}>
                    <Icon className={`h-6 w-6 ${feature.iconColor}`} />
                  </div>
                  <CardTitle>{feature.title}</CardTitle>
                  <CardDescription className="text-gray-600">
                    {feature.description}
                  </CardDescription>
                </CardHeader>
              </Card>
            );
          })}
        </div>
      </Container>
    </section>
  );
}
