import { api, ApiResponse } from './api';

export interface Post {
  id: string;
  title: string;
  slug: string;
  content: string;
  description: string;
  imageUrl?: string;
  tags?: string[];
  category: string;
  type: 'post' | 'page';
  format: 'markdown' | 'html';
  createdAt: string;
  updatedAt: string;
}

export interface PostListParams {
  keyword?: string;
  tags?: string[];
  category?: string;
  limit?: number;
  offset?: number;
}

export interface PostListResponse {
  posts: Post[];
  total: number;
}

class PostService {
  async getPost(slug: string): Promise<Post> {
    try {
      const response = await api.get<ApiResponse<Post>>(`/api/posts/${slug}`);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async getPosts(params: PostListParams = {}): Promise<PostListResponse> {
    try {
      const response = await api.get<ApiResponse<PostListResponse>>('/api/posts', {
        params,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
}

export const postService = new PostService();
