import { ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Container } from "@/components/ui/container";

export function HeroSection() {
  const navigate = useNavigate();

  return (
    <section className="py-20 bg-gradient-to-b from-emerald-50 to-white">
      <Container>
        <div className="text-center space-y-6">
          <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl bg-gradient-to-r from-emerald-600 to-teal-600 bg-clip-text text-transparent leading-normal md:leading-normal">
            Cỗ máy sinh ra sự giàu có
          </h1>
          <p className="mx-auto max-w-[700px] text-gray-600 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
            Nơi những nghiệp tốt được nuôi dưỡng và phát triển mỗi ngày
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Button 
              size="lg" 
              onClick={() => navigate("/register")}
              className="bg-gradient-to-r from-emerald-500 to-teal-600 hover:from-emerald-600 hover:to-teal-700 w-full sm:w-auto"
            >
              Bắt đầu ngay
              <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
            <Button 
              variant="outline" 
              size="lg" 
              onClick={() => navigate("/login")}
              className="w-full sm:w-auto"
            >
              Đăng nhập
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
}
