import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import { postService } from '@/services/post.service';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { Header } from '@/components/common/Header';
import { Footer } from '@/components/common/Footer';
import { Container } from "@/components/ui/container";
import { HomeLayout } from '@/app/layouts/HomeLayout';

export const PostListPage: React.FC = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['posts'],
    queryFn: () => postService.getPosts({ limit: 10 }),
  });

  if (isLoading) {
    return (
      <HomeLayout>
        <Container className="container w-full max-w-6xl mx-auto py-20">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[...Array(6)].map((_, index) => (
              <div key={index} className="space-y-4">
                <Skeleton className="h-48 w-full rounded-lg" />
                <Skeleton className="h-6 w-3/4" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-2/3" />
              </div>
            ))}
          </div>
        </Container>
      </HomeLayout>
    );
  }

  if (error) {
    return (
      <HomeLayout>
        <Container className="container max-w-2xl mx-auto py-20">
          <Alert variant="destructive">
            <AlertTitle>Lỗi</AlertTitle>
            <AlertDescription>
              Không thể tải danh sách bài viết. Vui lòng thử lại sau.
            </AlertDescription>
          </Alert>
        </Container>
      </HomeLayout>
    );
  }

  if (!data?.posts?.length) {
    return (
      <HomeLayout>
        <Container className="container max-w-2xl mx-auto py-20">
          <Alert>
            <AlertTitle>Thông báo</AlertTitle>
            <AlertDescription>
              Chưa có bài viết nào
            </AlertDescription>
          </Alert>
        </Container>
      </HomeLayout>
    );
  }

  return (
    <HomeLayout>
      <Container className="container mx-auto py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {data.posts.map((post) => (
            <Link key={post.id} to={`/${post.slug}`} className="block">
              <Card className="h-full overflow-hidden hover:shadow-lg transition-shadow">
                {post.imageUrl && (
                  <div className="h-48 w-full overflow-hidden">
                    <img
                      alt={post.title}
                      src={post.imageUrl}
                      className="h-full w-full object-cover transition-transform hover:scale-105"
                    />
                  </div>
                )}
                <CardHeader>
                  <CardTitle className="line-clamp-2">{post.title}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground mb-4 line-clamp-2">
                    {post.description}
                  </p>
                  {post.tags && post.tags.length > 0 && (
                    <div className="flex gap-2 flex-wrap">
                      {post.tags.slice(0, 3).map((tag) => (
                        <span
                          key={tag}
                          className="px-2 py-1 bg-muted rounded-full text-xs"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      </Container>
    </HomeLayout>
  );
};
