import { Container } from "@/components/ui/container";
import { Link } from "react-router-dom";

const footerSections = [
  {
    title: "Sản phẩm",
    links: [
      { label: "Tính năng", href: "/tinh-nang" },
      { label: "Bảng giá", href: "/bang-gia" },
      { label: "Hướng dẫn", href: "/huong-dan-su-dung" },
      { label: "Trợ giúp", href: "/tro-giup" },
    ],
  },
  {
    title: "Công ty",
    links: [
      { label: "Về chúng tôi", href: "/ve-chung-toi" },
      { label: "Blog", href: "/blog" },
      { label: "Đội ngũ", href: "/doi-ngu" },
      { label: "Tuyển dụng", href: "/tuyen-dung" },
    ],
  },
  {
    title: "Tài nguyên",
    links: [
      { label: "Cộng đồng", href: "https://www.facebook.com/groups/1201284814293598", external: true },
      { label: "Đối tác", href: "/doi-tac" },
      { label: "Tài liệu API", href: "/tai-lieu-api" },
      { label: "Trạng thái hệ thống", href: "/trang-thai-he-thong" },
    ],
  },
  {
    title: "Pháp lý",
    links: [
      { label: "Điều khoản sử dụng", href: "/dieu-khoan-su-dung" },
      { label: "Chính sách bảo mật", href: "/chinh-sach-bao-mat" },
      { label: "Bảo mật dữ liệu", href: "/bao-mat-du-lieu" },
      { label: "Cookie", href: "/cookie" },
    ],
  },
];

const socialLinks = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/groups/1201284814293598",
    icon: (
      <path
        fillRule="evenodd"
        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
        clipRule="evenodd"
      />
    ),
  },
  {
    name: "Youtube",
    href: "https://www.youtube.com/@wegreatacademy",
    icon: (
      <path
        fillRule="evenodd"
        d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
        clipRule="evenodd"
      />
    ),
  },
];

export function Footer() {
  return (
    <footer className="bg-gray-50 border-t">
      <Container>
        <div className="py-12">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {footerSections.map((section) => (
              <div key={section.title} className="space-y-4">
                <h3 className="font-semibold text-gray-900">{section.title}</h3>
                <ul className="space-y-2">
                  {section.links.map((link) => link.external ? (
                    <li key={link.label}><a
                      key={link.label}
                      href={link.href}
                      className="text-gray-600 hover:text-emerald-600 text-sm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.label}
                    </a></li>
                  ) : (
                    <li key={link.label}><Link
                      key={link.label}
                      to={link.href}
                      className="text-gray-600 hover:text-emerald-600 text-sm"
                    >
                      {link.label}
                    </Link></li>
                  )
                  )
                  }
                </ul>
              </div>
            ))}
          </div>

          {/* Bottom footer */}
          <div className="pt-8 mt-8 border-t">
            <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
              <div className="flex items-center space-x-4">
                <span className="text-sm text-gray-600"> 2024 Cỗ Máy Giàu Có. All rights reserved.</span>
              </div>
              <div className="flex items-center space-x-6">
                {socialLinks.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-gray-600 hover:text-emerald-600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">{item.name}</span>
                    <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                      {item.icon}
                    </svg>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}
