import { Container } from "@/components/ui/container";
import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";

const steps = [
  {
    step: "01",
    title: "Tạo tài khoản",
    description: "Đăng ký nhanh chóng và bắt đầu hành trình của bạn",
  },
  {
    step: "02",
    title: "Thiết lập mục tiêu",
    description: "Xác định mục tiêu và lập kế hoạch phát triển",
  },
  {
    step: "03",
    title: "Phát triển mỗi ngày",
    description: "Ghi chép, theo dõi và nhận được sự hướng dẫn từ AI",
  },
];

export function HowItWorksSection() {
  return (
    <section className="py-20 bg-gradient-to-t from-emerald-50 to-white">
      <Container>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
            Bắt đầu dễ dàng
          </h2>
          <p className="mt-4 text-gray-500 md:text-xl/relaxed">
            Ba bước đơn giản để bắt đầu hành trình phát triển
          </p>
        </div>
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {steps.map((item) => (
            <Card key={item.step} className="relative overflow-hidden border-0 shadow-lg">
              <CardHeader>
                <div className="absolute -top-4 -left-4 w-16 h-16 bg-gradient-to-br from-emerald-500/10 to-teal-500/10 rounded-full flex items-center justify-center">
                  <span className="text-2xl font-bold text-emerald-600">{item.step}</span>
                </div>
                <div className="ml-8">
                  <CardTitle>{item.title}</CardTitle>
                  <CardDescription className="text-gray-600">{item.description}</CardDescription>
                </div>
              </CardHeader>
            </Card>
          ))}
        </div>
      </Container>
    </section>
  );
}
