import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { postService } from '@/services/post.service';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Skeleton } from '@/components/ui/skeleton';
import { Container } from "@/components/ui/container";
import { HomeLayout } from '@/app/layouts/HomeLayout';

export const PostDetailPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();

  const { data: post, isLoading, error } = useQuery({
    queryKey: ['post', slug],
    queryFn: () => postService.getPost(slug!),
    enabled: !!slug,
  });

  if (isLoading) {
    return (
      <HomeLayout>
        <Container className="container mx-auto max-w-3xl py-20">
          <div className="space-y-8">
            <Skeleton className="h-12 w-3/4 mx-auto" />
            <div className="space-y-4">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-3/4" />
            </div>
          </div>
        </Container>
      </HomeLayout>
    );
  }

  if (error || !post) {
    return (
      <HomeLayout>
        <Container className="container max-w-2xl mx-auto py-20">
          <Alert variant="destructive">
            <AlertTitle>Lỗi</AlertTitle>
            <AlertDescription>
              Không thể tải bài viết. Vui lòng thử lại sau.
            </AlertDescription>
          </Alert>
        </Container>
      </HomeLayout>
    );
  }

  return (
    <HomeLayout>
      {/* Hero section with full-width background */}
      {post.type !== 'page' && (
        <Container className="container mx-auto pt-20">
          <h1 className="text-4xl sm:text-5xl font-bold text-gray-900 mb-6">{post.title}</h1>
          <div className="flex gap-4 text-gray-600 items-center">
            <time dateTime={post.createdAt}>{new Date(post.createdAt).toLocaleDateString('vi-VN')}</time>
            <span>•</span>
            <span className="text-emerald-600">{post.category}</span>
          </div>
          {/* Featured image section */}
          {post.imageUrl && (
            <div className="relative mt-4">
              <Container className="max-w-5xl mx-auto">
                <div className="aspect-[2/1] relative rounded-xl overflow-hidden shadow-xl">
                  <img
                    src={post.imageUrl}
                    alt={post.title}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
              </Container>
            </div>
          )}
        </Container>
      )}

      {/* Content section */}

      {post.format === 'markdown' ? (
        <Container className="container mx-auto mt-20">
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
            className="prose prose-lg max-w-none prose-headings:text-gray-900 prose-p:text-gray-600 prose-a:text-emerald-600 prose-a:no-underline hover:prose-a:underline prose-strong:text-gray-900 prose-img:rounded-xl"
          >
            {post.content}
          </ReactMarkdown></Container>
      ) : (
        <div className="mt-16" dangerouslySetInnerHTML={{ __html: post.content }} />
      )}

      {/* Tags section */}
      {post.type !== 'page' && post.tags && post.tags.length > 0 && (
        <div className="mt-16 py-8 border-t border-gray-100">
          <div className="flex flex-wrap gap-2 justify-center">
            {post.tags.map((tag) => (
              <span
                key={tag}
                className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 transition-colors"
              >
                {tag}
              </span>
            ))}
          </div>
        </div>
      )}
    </HomeLayout>
  );
};
