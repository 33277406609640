import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

type CurrencyLocale = 'vi' | 'en';

interface FormatCurrencyOptions {
  isShort?: boolean;
  locale?: CurrencyLocale;
}

const CURRENCY_UNITS = {
  vi: {
    billion: 'tỉ',
    million: 'triệu',
    thousand: 'k',
    currency: 'đ'
  },
  en: {
    billion: 'B',
    million: 'M',
    thousand: 'K',
    currency: 'VND'
  }
} as const;

export function formatCurrency(amount: number, options: FormatCurrencyOptions = {}): string {
  const { isShort = false, locale = 'vi' } = options;
  const units = CURRENCY_UNITS[locale];

  if (isShort) {
    const absAmount = Math.abs(amount);
    const sign = amount < 0 ? '-' : '';

    if (absAmount >= 1000000000) {
      const billions = absAmount / 1000000000;
      return `${sign}${billions.toFixed(billions < 10 ? 1 : 0)} ${units.billion}`;
    }
    
    if (absAmount >= 1000000) {
      const millions = absAmount / 1000000;
      return `${sign}${millions.toFixed(millions < 10 ? 1 : 0)} ${units.million}`;
    }
    
    if (absAmount >= 1000) {
      const thousands = absAmount / 1000;
      return `${sign}${Math.round(thousands)} ${units.thousand}`;
    }

    return `${sign}${absAmount} ${units.currency}`;
  }

  return new Intl.NumberFormat(locale === 'vi' ? 'vi-VN' : 'en-US', {
    style: 'currency',
    currency: 'VND',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
}

/**
 * Rounds a number to a specified number of decimal places
 * @param num - The number to round
 * @param decimals - Number of decimal places (default: 2)
 * @returns The rounded number
 */
export function roundNumber(num: number, decimals: number = 2): number {
  return Number(Math.round(Number(num + 'e' + decimals)) + 'e-' + decimals)
}

export function generateUUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

import { CATEGORY_ICONS, CATEGORY_LABELS, CategoryType } from "@/types/category";
import { Transaction } from "@/types/transaction";

export function getCategoryIcon(categoryType: string) {
  const iconName = CATEGORY_ICONS[categoryType as CategoryType] || 'DollarSign';
  return iconName;
}

export function getCategoryLabel(categoryType: string) {
  return CATEGORY_LABELS[categoryType as CategoryType] || categoryType;
}

export function getTransactionDescription(transaction: Transaction) {
  switch (transaction.type) {
    case 'borrow':
      return `Vay từ ${transaction.description}`;
    case 'lend':
      return `Cho ${transaction.description} vay`;
    case 'repay':
      return `Trả nợ cho ${transaction.description}`;
    case 'collect':
      return `Thu nợ từ ${transaction.description}`;
    case 'transfer_refundable':
      return `Gửi tạm ${transaction.description}`;
    case 'transfer_non_refundable':
      return `Chuyển cho ${transaction.description}`;
    case 'money_transfer':
      return `Chuyển tiền ${transaction.description}`;
    case 'allocation':
      return `Phân bổ ${transaction.description}`;
    default:
      return transaction.description || '-';
  }
}

export function getTransactionColor(type: string) {
  switch (type) {
    case 'income':
    case 'collect':
      return 'text-green-600';
    case 'expense':
    case 'lend':
    case 'transfer_non_refundable':
      return 'text-red-600';
    case 'borrow':
    case 'repay':
    case 'transfer_refundable':
    case 'money_transfer':
    case 'allocation':
      return '';
    default:
      return 'text-gray-600';
  }
}

export function getTransactionPrefix(type: string) {
  switch (type) {
    case 'income':
    case 'collect':
    case 'borrow':
      return '+';
    case 'expense':
    case 'lend':
    case 'repay':
    case 'transfer_refundable':
    case 'transfer_non_refundable':
      return '-';
    case 'money_transfer':
    case 'allocation':
      return '';  // Không hiển thị dấu vì phụ thuộc vào chiều giao dịch
    default:
      return '';
  }
}
