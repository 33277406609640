import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { CheckCircle2 } from 'lucide-react';
import { AuthLayout } from '../../../app/layouts/AuthLayout';

export function VerifyEmailPage() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => navigate('/login'), 6000);
  }, [navigate]);

  return (
    <AuthLayout 
      title="Xác thực email thành công"
      subtitle="Email của bạn đã được xác thực thành công. Bạn có thể đăng nhập để bắt đầu sử dụng dịch vụ."
    >
      <div className="space-y-6">
        <Alert className="bg-green-50 border-green-200">
          <CheckCircle2 className="h-4 w-4 text-green-600" />
          <AlertDescription className="text-green-800">
            Email của bạn đã được xác thực thành công!
          </AlertDescription>
        </Alert>
        <p className="text-center text-gray-600">
          Bạn sẽ được chuyển đến trang đăng nhập trong vài giây...
        </p>
        <div className="flex justify-center">
          <Button asChild className="bg-gradient-to-r from-emerald-500 to-teal-600 hover:from-emerald-600 hover:to-teal-700">
            <Link to="/login">Đăng nhập ngay</Link>
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
}
