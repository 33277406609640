import { useEffect } from 'react';
import { RegisterForm } from '../components/RegisterForm';
import { useAuthStore } from '@/stores/auth.store';
import { AuthLayout } from '../../../app/layouts/AuthLayout';
import { useMachineStore } from '@/stores/machine.store';
import { useQueryClient } from '@tanstack/react-query';

export function RegisterPage() {
  const { logout } = useAuthStore();
  const { resetMachines } = useMachineStore();
  const queryClient = useQueryClient();

  useEffect(() => {
    // Clear all data when visiting register page
    logout();
    resetMachines();
    queryClient.clear();
  }, []);

  return (
    <AuthLayout
      title="Đăng ký tài khoản"
      subtitle="Tạo tài khoản mới để bắt đầu sử dụng Wealth Machine."
      type="register"
    >
      <RegisterForm />
    </AuthLayout>
  );
}
