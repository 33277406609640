import { Machine, MachineUpdateRequest, UpdateMachineFundRequest, CreateMachineRequest } from '@/types/machine'
import { api, ApiResponse } from './api'

export interface Fund {
  name: string
  icon: string
  percent: number
}

export interface Store {
  name: string
  type: 'income' | 'expense' | 'reserve' | 'expansion' | 'business'
  icon: string
  funds: Fund[]
}

export interface Wallet {
  name: string
  type: 'cash' | 'bank'
  icon: string
  balance: number
  currency: string
}

export interface MachineTemplate {
  stores: Store[]
  wallets: Wallet[]
}

class MachineService {
  async list(): Promise<Machine[]> {
    try {
      const response = await api.get<ApiResponse<Machine[]>>('/api/machine/list')
      return response.data.data
    } catch (error) {
      console.error('MachineService: Error fetching machines:', error)
      throw error
    }
  }

  async create(data: CreateMachineRequest): Promise<Machine> {
    try {
      const response = await api.post<ApiResponse<Machine>>('/api/machine/create', data)
      return response.data.data
    } catch (error) {
      console.error('MachineService: Error creating machine:', error)
      throw error
    }
  }

  async get(): Promise<Machine> {
    try {
      const response = await api.get<ApiResponse<Machine>>('/api/machine')
      return response.data.data
    } catch (error) {
      console.error('MachineService: Error fetching machine:', error)
      throw error
    }
  }

  async saveBasicInfo(data: MachineUpdateRequest): Promise<Machine> {
    try {
      const response = await api.put<ApiResponse<Machine>>(`/api/machine/basic-info`, data)
      return response.data.data
    } catch (error) {
      console.error('MachineService: Error saving basic info:', error)
      throw error
    }
  }

  async saveStoresFunds(data: UpdateMachineFundRequest): Promise<Machine> {
    try {
      const response = await api.put<ApiResponse<Machine>>(`/api/machine/stores-funds`, data)
      return response.data.data
    } catch (error) {
      console.error('MachineService: Error saving stores funds:', error)
      throw error
    }
  }

  async delete(): Promise<void> {
    try {
      await api.delete(`/api/machine`)
    } catch (error) {
      console.error('MachineService: Error deleting machine:', error)
      throw error
    }
  }

  async getMinimal(): Promise<Machine> {
    try {
      const response = await api.get<ApiResponse<Machine>>(`/api/machine/minimal`)
      return response.data.data
    } catch (error) {
      console.error('MachineService: Error fetching minimal machine:', error)
      throw error
    }
  }

  async getTemplate(): Promise<MachineTemplate> {
    try {
      const response = await api.get<ApiResponse<MachineTemplate>>('/api/config/template')
      return response.data.data
    } catch (error) {
      console.error('MachineService: Error fetching template:', error)
      // Return default template if API fails
      return {
        stores: [
          {
            name: 'Thu nhập',
            type: 'income',
            icon: 'wallet',
            funds: []
          },
          {
            name: 'Chi tiêu',
            type: 'expense',
            icon: 'shopping-cart',
            funds: []
          },
          {
            name: 'Dự phòng',
            type: 'reserve',
            icon: 'shield',
            funds: []
          },
          {
            name: 'Mở rộng',
            type: 'expansion',
            icon: 'trending-up',
            funds: []
          }
        ],
        wallets: [
          {
            name: 'Tiền mặt',
            type: 'cash',
            icon: 'banknote',
            balance: 0,
            currency: 'VND'
          },
          {
            name: 'Ngân hàng',
            type: 'bank',
            icon: 'building-bank',
            balance: 0,
            currency: 'VND'
          }
        ]
      }
    }
  }
}

export const machineService = new MachineService()
