import { Card } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { formatCurrency, getCategoryIcon, getCategoryLabel, getTransactionDescription, getTransactionColor, getTransactionPrefix } from "@/lib/utils";
import { IconMapper } from "@/components/common/IconMapper";
import { TransactionWithCategory } from "@/types/transaction";
import { formatDistanceToNow } from 'date-fns';
import { vi } from 'date-fns/locale';
import { Separator } from "@/components/ui/separator";

interface RecentTransactionsProps {
  transactions: TransactionWithCategory[];
  isLoading?: boolean;
}

export function RecentTransactions({ transactions, isLoading }: RecentTransactionsProps) {
  if (isLoading) {
    return (
      <Card className="w-full">
        <div className="p-6">
          <h2 className="text-lg font-semibold mb-4">Các giao dịch gần đây</h2>
          <div>
            {[1, 2, 3].map((index) => (
              <div key={index}>
                <div className="flex items-center gap-3 py-3">
                  <Skeleton className="w-10 h-10 rounded-xl flex-shrink-0" />
                  <div className="flex-1 flex items-center justify-between min-w-0">
                    <div className="min-w-0 space-y-1.5">
                      <Skeleton className="h-4 w-24" />
                      <Skeleton className="h-4 w-32" />
                    </div>
                    <Skeleton className="h-4 w-20" />
                  </div>
                </div>
                {index < 2 && <Separator />}
              </div>
            ))}
          </div>
        </div>
      </Card>
    );
  }

  if (!transactions?.length) {
    return (
      <Card className="w-full">
        <div className="p-6">
          <h2 className="text-lg font-semibold mb-4">Các giao dịch gần đây</h2>
          <div className="text-center text-sm text-muted-foreground">
            No transactions found
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card className="w-full">
      <div className="px-6 pt-6">
        <h2 className="text-lg font-semibold mb-4">Các giao dịch gần đây</h2>
      </div>
      <div>
        {transactions.map((transaction, index) => (
          <div key={transaction.id}>
            <div className="flex items-center gap-3 p-3">
              <div className="flex items-center justify-center w-10 h-10 bg-muted rounded-xl flex-shrink-0">
                <IconMapper
                  iconName={getCategoryIcon(transaction.categoryType)}
                  className="w-5 h-5"
                />
              </div>

              <div className="flex-1 flex items-center justify-between min-w-0">
                <div className="min-w-0">
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {getCategoryLabel(transaction.categoryType)}
                    </p>
                    <p className="text-xs text-gray-400">
                      {formatDistanceToNow(new Date(transaction.created_at), {
                        addSuffix: true,
                        locale: vi
                      })}
                    </p>
                  </div>
                  {transaction.related_transactions && transaction.related_transactions.length > 0 && (
                    <p className="text-xs text-gray-500 mt-0.5">
                      ({transaction.related_transactions.length + 1} giao dịch)
                    </p>
                  )}
                </div>
                <p className={`text-sm font-medium whitespace-nowrap ${getTransactionColor(transaction.type)}`}>
                  {getTransactionPrefix(transaction.type)}{formatCurrency(Math.abs(transaction.amount))}
                </p>
              </div>
            </div>
            {index < transactions.length - 1 && <Separator />}
          </div>
        ))}
      </div>
    </Card>
  );
}
