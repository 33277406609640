import { create } from 'zustand';
import { reportService } from '@/services/report.service';
import { transactionService } from '@/services/transaction.service';
import { format } from 'date-fns';
import { toast } from '@/hooks/use-toast';
import { TransactionWithCategory, Transaction } from '@/types/transaction';
import { CategoryType } from '@/types/category';

interface DashboardState {
  isLoading: boolean;
  error: string | null;
  monthlySummary: {
    month: number;
    income: number;
    expense: number;
  } | null;
  recentTransactions: TransactionWithCategory[];
  alerts: Array<{
    type: 'warning' | 'error';
    title: string;
    description: string;
  }>;

  fetchDashboardData: () => Promise<void>;
}

const mapTransactionTypeToCategory = (type: string, transaction: Transaction): CategoryType => {
  switch (type) {
    case 'expense':
      return 'shopping';
    case 'income':
      return 'income';
    case 'borrow':
    case 'lend':
    case 'repay':
    case 'collect':
      return 'debt';
    case 'transfer_refundable':
    case 'transfer_non_refundable':
    case 'money_transfer':
    case 'allocation':
      return 'transfer';
    default:
      return 'other';
  }
};

export const useDashboardStore = create<DashboardState>((set, get) => ({
  isLoading: false,
  error: null,
  monthlySummary: {
    month: new Date().getMonth() + 1,
    income: 0,
    expense: 0
  },
  recentTransactions: [],
  alerts: [],

  fetchDashboardData: async () => {
    try {
      set({ isLoading: true, error: null });

      // Fetch monthly summary
      const currentDate = new Date();
      const startDate = format(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd');
      const endDate = format(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0), 'yyyy-MM-dd');
      
      const [summary, transactionsResponse] = await Promise.all([
        reportService.getReport({
          startDate,
          endDate,
        }),
        transactionService.filter({
          startDate,
          endDate,
          limit: 10,
          offset: 0
        })
      ]);

      // Transform transactions to include categoryType
      const recentTransactions: TransactionWithCategory[] = transactionsResponse.transactions.map(transaction => ({
        ...transaction,
        categoryType: mapTransactionTypeToCategory(transaction.type, transaction),
        description: transaction.note || undefined
      }));

      set({
        monthlySummary: {
          month: currentDate.getMonth() + 1,
          income: summary.income || 0,
          expense: Math.abs(summary.expense || 0)
        },
        recentTransactions,
        isLoading: false
      });
    } catch (error: any) {
      set({ 
        error: error.message, 
        isLoading: false,
        monthlySummary: {
          month: new Date().getMonth() + 1,
          income: 0,
          expense: 0
        }
      });
      toast({
        title: 'Error',
        description: 'Failed to fetch dashboard data',
        variant: 'destructive',
      });
    }
  }
}));
