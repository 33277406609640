import { HomeLayout } from "@/app/layouts/HomeLayout";
import { HeroSection } from "../components/HeroSection";
import { FeaturesSection } from "../components/FeaturesSection";
import { HowItWorksSection } from "../components/HowItWorksSection";

export function LandingPage() {
  return (
    <HomeLayout>
      <HeroSection />
      <FeaturesSection />
      <HowItWorksSection />
    </HomeLayout>
  );
}